import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'
import { t } from '@/libs_sz/locale'

const getFormatter = (data, value) => {
  const getValue = myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' ? '' : getValue
}

const getValue = value => (value ? String(value) : String(value) === '0' ? '0' : '')

const defaultFlag = [{
  label: t('lang.gles.common.yes'),
  value: 1
},
{
  label: t('lang.gles.common.no'),
  value: 0
}
]

export const getMoreQueryFormData = (that, options) => {
  const moreQueryData = [
    // 批属性编码
    {
      name: 'batchPropertyCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.base.batchPropertyCode'),
      clearable: true
    },
    // 批属性名称
    {
      name: 'batchPropertyName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.batchPropertyeName'),
      clearable: true
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 批属性编码
    {
      prop: 'batchPropertyCode',
      label: that.$t('lang.gles.material.batchPropertyCode'),
      isShow: true,
      slotName: 'clickSlot'
    },
    // 批属性名称
    {
      prop: 'batchPropertyName',
      label: that.$t('lang.gles.material.batchPropertyName'),
      isShow: true
    },
    // 默认批属性
    {
      prop: 'defaultFlag',
      label: that.$t('lang.gles.material.defaultBatchProperty'),
      isShow: true,
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(defaultFlag, row[column])
      }
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      minWidth: 72,
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      isShow: true,
      formatter: { type: 'time' }
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      minWidth: 72,
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      isShow: true,
      formatter: { type: 'time' }
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row, mode) => {
  const propertyFormItem = [
    // 批属性编码
    {
      name: 'batchPropertyCode',
      value: row.batchPropertyCode || '',
      span: 8,
      component: 'elInput',
      maxLength: 50,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.material.batchPropertyCode'),
      placeholder: that.$t('lang.gles.common.plaeseEnterCombination'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true,
      disabled: mode === 'edit' || mode === 'detail'
    },
    // 批属性名称
    {
      name: 'batchPropertyName',
      value: row.batchPropertyName || '',
      span: 8,
      maxLength: 50,
      component: 'elInput',
      label: that.$t('lang.gles.material.batchPropertyName'),
      rules: mapVerify(['required', 'inputName']),
      clearable: true,
      disabled: mode === 'detail'
    },
    // 默认批属性
    {
      name: 'defaultFlag',
      value: row.defaultFlag || '',
      span: 8,
      component: 'elSwitch',
      activeValue: 1,
      inactiveValue: 0,
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      class: 'switch',
      label: that.$t('lang.gles.material.defaultBatchProperty'),
      disabled: mode === 'detail'
    }
  ]
  // 给每一项绑定mchange事件
  propertyFormItem.forEach((item) => {
    if (!item.mchange && mode === 'detail') {
      item.mchange = that.onPropertyFormChange
    }
  })
  return propertyFormItem
}

// 获取表格表单项
export const getBatchPropertyTableItem = (that, row, mode) => {
  // console.log(row, 'rowwww', mode)
  const batchPropertyTableItem = [
    // 属性标签
    {
      prop: 'propertyLabelName',
      component: 'elInput',
      label: that.$t('lang.gles.material.batchPropertyLabel'),
      rules: mapVerify(['inputLen50']),
      appendAttrsFn(row, index) {
        // console.log(row,"rowwww");
        if (mode === 'edit' || mode === 'detail') return { disabled: true }
        return {
          disabled: row.propertyLabelCode === 'proDate' || row.propertyLabelCode === 'expDate' || row.propertyLabelCode === 'outBatch'
        }
      },
      isShow: true
    },
    // 属性格式
    {
      prop: 'propertyLabelPattern',
      component: 'elSelect',
      label: that.$t('lang.gles.material.batchPropertyFormat'),
      data: propertyPatternData,
      clearable: true,
      appendAttrsFn(row, index) {
        if (mode === 'edit' || mode === 'detail') return { disabled: true }
        return {
          disabled: row.propertyLabelCode === 'proDate' || row.propertyLabelCode === 'expDate' || row.propertyLabelCode === 'outBatch'
        }
      },
      watch(value, rowData, $index, vm) {
        if (value === 4) {
          vm.setRowItemDataByIndex($index, { ...rowData, defaultValue: 1 })
        } else {
          vm.setRowItemDataByIndex($index, { ...rowData, defaultValue: '' })
        }
      },
      isShow: true
    },
    // 输入控制
    {
      prop: 'requiredFlag',
      label: that.$t('lang.gles.material.inputControl'),
      component: 'elSelect',
      data: requiredFlagData || [],
      isShow: true,
      disabled: mode === 'detail',
      clearable: false
    },
    // 属性默认值
    {
      prop: 'defaultValue',
      label: that.$t('lang.gles.material.propertyDefaultVal'),
      // rules: mapVerify(['positiveNum']),
      component(row) {
        const component = {
          1: 'elSelect',
          2: 'elSelect',
          3: 'elInput',
          4: 'elInput'
        }[row.propertyLabelPattern] || 'elInput'
        return component
      },
      appendAttrsFn(row, index, item) {
        // console.log(row, '-----', index)
        if (item.component(row) === 'elInput' && row.propertyLabelPattern === 4) {
          return { rules: mapVerify(['positiveNum']), data: row.defaultValue || '', disabled: mode === 'detail' }
        }
        if (item.component(row) === 'elInput' && row.propertyLabelPattern === 3) {
          return { rules: mapVerify(['inputLen50']), data: row.defaultValue || '', disabled: mode === 'detail' }
        }
        return {
          disabled: mode === 'detail',
          data: row.propertyLabelPattern === 1 ? dateOption : row.propertyLabelPattern === 2 ? dateTimeOption : [],
          rules: mapVerify(['any'])
        }
      },
      formatter(row, column) {
        return getValue(row[column])
      },
      isShow: true,
      clearable: true
    },
    // 关键属性
    {
      prop: 'keyFlag',
      component: 'elCheckbox',
      label: that.$t('lang.gles.material.propertyKey'),
      isShow: true,
      clearable: true,
      disabled: mode === 'detail',
      formatter(row, column) {
        return String(row[column]) === '1'
      },
      watch(value, rowData, $index, vm) {
        vm.setRowItemDataByIndex($index, { ...rowData, enableFlag: true, requiredFlag: value ? 1 : 0 })
      }
    },
    // 启用
    {
      prop: 'enableFlag',
      label: that.$t('lang.gles.common.enable'),
      component: 'elCheckbox',
      appendAttrsFn(row, index, item) {
        return {
          disabled: mode === 'detail' || !!row.keyFlag
        }
      },
      formatter(row, column) {
        return String(row[column]) === '1'
      },
      watch(value, rowData, $index, vm) {
        // console.log(value,"val");
        vm.setRowItemDataByIndex($index, { ...rowData, requiredFlag: value ? 1 : 0 })
      },
      isShow: true,
      clearable: true
    }
  ]
  // batchPropertyTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return batchPropertyTableItem
}

// 属性格式 选项数据
export const propertyPatternData = [
  // {
  //   label: t('lang.gles.common.pleaseSelect'),
  //   value: 0
  // },
  {
    label: t('lang.gles.common.date'),
    value: 1
  },
  {
    label: t('lang.gles.common.dateTime'),
    value: 2
  },
  {
    label: t('lang.gles.common.character'),
    value: 3
  },
  {
    label: t('lang.gles.common.number'),
    value: 4
  }
]
// 选择控制
export const requiredFlagData = [
  {
    label: t('lang.gles.common.required'),
    value: 1
  },
  {
    label: t('lang.gles.common.optional'),
    value: 0
  }
]
// 日期
const dateOption = [
  { label: t('lang.gles.material.currentDate'), value: 'currentDate' },
  { label: t('lang.gles.common.empty'), value: '-1' }
]
// 日期时间
const dateTimeOption = [
  { label: t('lang.gles.material.currentDateTime'), value: 'currentDateTime' },
  { label: t('lang.gles.common.empty'), value: '-1' }
]
