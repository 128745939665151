<template>
  <el-drawer
    :visible="visible"
    :title="title"
    size="80%"
    :with-header="false"
    custom-class="gles-drawer"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button type="danger" @click="handleDelete">
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i
          class="el-icon-close"
          @click="$emit('update:visible', false)"
        />
      </div>
      <section class="add-module">
        <!-- 表单信息 -->
        <m-form
          ref="myForm"
          :form-data="batchPropertyFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </section>
      <div class="batchProperty">
        <table-form :table-item="tableFormItem" :table-data="tableFormData" />
      </div>
    </div>
  </el-drawer>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import TableForm from '@/components/tableForm/index.vue'
import { getEditBaseFormData, getBatchPropertyTableItem } from '../data'

export default {
  name: 'DetailBatchProoerty',
  components: {
    MForm,
    TableForm
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    /**
     * 可放子容器 默认是
     */
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      title: this.$t('lang.gles.material.batchPropertyDetail'),
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      formModel: {},
      batchPropertyTableFormData: [],
      options: {
        batchPropertyLabelList: []
      },
      updateTableList: []
    }
  },
  computed: {
    batchPropertyFormData() {
      const row = this.rowDetail
      console.log(row, 'row')
      return getEditBaseFormData(this, { ...row }, this.mode)
    },
    tableFormItem() {
      const row = this.rowDetail || {}
      return getBatchPropertyTableItem(this, { ...row }, this.mode)
    },
    tableFormData() {
      this.rowDetail.propertyDetail &&
        this.rowDetail.propertyDetail.forEach((item) => {
          item.propertyLabelPattern =
            item.propertyLabelPattern === 0 ? '' : item.propertyLabelPattern
          item.enableFlag = !!item.enableFlag
          item.keyFlag = !!item.keyFlag
        })
      return this.rowDetail.propertyDetail
    }
  },
  watch: {
    batchPropertyFormData() {
      this.$nextTick(() => {
        this.$refs?.myForm?.changeFormValue(this.rowDetail ?? {})
      })
    }
  },
  created() {},
  methods: {
    onPropertyFormChange(val, formItem) {
      if (typeof val === 'string') {
        this.$set(this.formModel, formItem.name, this._.trim(val))
      } else {
        this.$set(this.formModel, formItem.name, val)
      }
      this.$emit('update:rowDetail', this._.cloneDeep(this.formModel))
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'BatchPropertyList',
        mode: ''
      })
    },

    handleEdit() {
      this.$emit('update:visible', false)
      this.$emit('updateCom', {
        currentCom: 'BatchPropertyAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 删除
     */
    handleDelete() {
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$emit('update:visible', false)
          this.$parent.delItem({ row: this.rowDetail })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-drawer__header > span {
  font-size: 16px;
  font-weight: 600;
}
.batchProperty {
  // border: 1px solid red;
  padding: 20px 30px;
  max-height: 100%;
  overflow: scroll;
  /deep/ .el-checkbox__label {
    display: none;
  }
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    position: fixed;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
