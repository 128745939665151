<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="batchPropertyFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
      />
      <div class="batchProperty">
        <table-form
          ref="tableForm"
          :table-item="tableFormItem"
          :table-data="tableFormData"
          :extend-config="tableExtendConfig"
        />
      </div>
      <div class="footer-btns">
        <div class="btns">
          <el-button @click="onCancel">
            {{ $t('lang.gles.common.cancel') }}
          </el-button>
          <el-button v-debounce="onSubmit" type="primary">
            {{ $t('lang.gles.common.confirm') }}
            <!-- {{ $t("lang.gles.common.save") }} -->
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import TableForm from '@/components/tableForm/index.vue'
import { getEditBaseFormData, getBatchPropertyTableItem } from '../data'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AddBatchProoerty',
  components: {
    HeaderPath,
    MForm,
    TableForm
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      headerPaths:
        this.mode === 'add'
          ? [this.$t('lang.gles.material.batchPropertyAdd')]
          : [this.$t('lang.gles.material.batchPropertyEdit')],
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      // 排序相关
      tableExtendConfig: {
        operateWidth: 120,
        sortCondition() {
          return true
        }
      },

      formModel: {}
    }
  },
  computed: {
    ...mapState('materialManage', ['batchPropertyLabelList']),
    batchPropertyFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row }, this.mode)
    },
    tableFormItem() {
      const row = this.rowDetail || {}
      return getBatchPropertyTableItem(this, { ...row }, this.mode)
    },
    tableFormData() {
      if (this.mode === 'add') {
        const propertyList = this.batchPropertyLabelList.map((item, index) => {
          const labelFormat =
            item.value === 'proDate' || item.value === 'expDate'
              ? 2
              : item.value === 'outBatch'
              ? 3
              : ''
          const flag =
            item.value === 'proDate' ||
            item.value === 'expDate' ||
            item.value === 'outBatch'
          return {
            propertyLabelName: this.$t(item.label),
            propertyLabelCode: item.value,
            propertyLabelDesc: item.label,
            propertyLabelPattern: labelFormat,
            requiredFlag: flag ? 1 : 0,
            defaultValue: '',
            propertyLabelOrder: index,
            keyFlag: item.value === 'proDate',
            enableFlag: flag
          }
        })
        return propertyList
      }

      if (this.rowDetail) {
        const { propertyDetail } = this.rowDetail
        this.rowDetail.propertyDetail.forEach((item) => {
          item.propertyLabelPattern =
            item.propertyLabelPattern === 0 ? '' : item.propertyLabelPattern
          item.enableFlag = !!item.enableFlag
          item.keyFlag = !!item.keyFlag
        })

        propertyDetail.sort((pData, nData) => {
          return pData.propertyLabelOrder - nData.propertyLabelOrder
        })
      }

      return this.rowDetail.propertyDetail
    }
  },
  created() {
    this.getBatchPropertyLabelList('batchPropertyLabel')
  },
  methods: {
    ...mapActions('materialManage', ['getBatchPropertyLabelList']),
    /**
     * 属性格式 事件处理
     */
    handlePropertyPattern(val, formItem, changeFormValue, formModel) {
      console.log(val, formItem, formModel)
    },
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        this.$refs.tableForm.validateTableForm().then(async () => {
          if (
            !this.$refs.tableForm
              .getUpdateTableData()
              .some((item) => !!item.enableFlag)
          ) {
            this.$message.error(this.$t('lang.gles.material.checkMessage'))
            return
          }
          const params = { ...this.rowDetail, ...model }
          params.propertyDetail = this.$refs.tableForm
            .getUpdateTableData()
            .map((item, propertyLabelOrder) => {
              return {
                ...item,
                enableFlag: item.enableFlag ? 1 : 0,
                keyFlag: item.keyFlag ? 1 : 0,
                propertyLabelOrder
              }
              // item.defaultValue = String(item.defaultValue)
            })
          // console.log(params, '-======')
          this.mode === 'add'
            ? await this.insert(params)
            : await this.update(params)
        })
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.materialManage('addBatchProperty'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    async update(params) {
      delete params.updateTime
      delete params.updateUser
      delete params.createTime
      delete params.createUser
      if (params.propertyDetail) {
        params.propertyDetail.map((item) => {
          delete item.updateTime
          delete item.createTime
          return item
        })
      }
      const { code } = await this.$httpService(
        this.$apiStore.materialManage('updateBatchProperty'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'BatchPropertyList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.batchProperty {
  // border: 1px solid red;
  padding: 20px 30px;
  max-height: 600px;
  overflow: scroll;
  /deep/ .el-checkbox__label {
    display: none;
  }
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
