<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :is-show-more-search="false"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.batchPropertyCode }}
          </span>
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog v-if="detailDialogVisible" :visible.sync="detailDialogVisible" :row-detail="detailRow" @updateCom="editItem" />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import DetailDialog from './detail'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'

export default {
  name: 'BatchPropertyList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: ''
      },
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      detailRow: {},
      detailDialogVisible: false
    }
  },
  computed: {
    moreQueryData: function() {
      return getMoreQueryFormData(this, { ...this.options })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, { ...this.options }, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter((item, i) => item.name === this.columnConfigList[i]?.name && this.columnConfigList[i]?.isShow)
      }
      return list
    }
  },
  async created() {
    this.doSearch(this.baseFormModel)
  },
  methods: {
    // 查询
    async doSearch(params) {
      try {
        const { data, code } = await this.$httpService(this.$apiStore.materialManage('getBatchPropertyList', this.pageData), params)
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const { recordList = [], currentPage, pageSize, totalRecordCount: recordCount } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 新增
    updateCom() {
      this.$emit('updateCom', {
        currentCom: 'BatchPropertyAdd',
        mode: 'add'
      })
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },

    async editItem({ row }) {
      // 获取批属性详情
      const { data, code } = await this.$httpService(this.$apiStore.materialManage('queryBatchPropertyDetail'), { id: row.id })
      if (code !== 0) return

      row.propertyDetail = data?.propertyDetail

      this.$emit('updateCom', {
        currentCom: 'BatchPropertyAdd',
        mode: 'edit',
        row
      })
    },
    // 查看详情
    async handleDetail(row) {
      this.detailDialogVisible = true
      // 获取批属性详情
      const { data, code } = await this.$httpService(this.$apiStore.materialManage('queryBatchPropertyDetail'), { id: row.id })
      if (code !== 0) return
      row.propertyDetail = data?.propertyDetail
      this.detailRow = row
      this.mode = 'detail'
    },
    // 删除商品
    async delItem({ row }) {
      const { code } = await this.$httpService(this.$apiStore.materialManage('deleteBatchProperty'), { id: row.id })
      if (code !== 0) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData.length < 2) this.resetPageData()
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
